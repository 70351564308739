<template>
  <div class="find-result" :class="{skeleton:!state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <span>아이디 찾기 결과</span>
      </div>
      <div class="desc font-sm">
        <span>아이디와 가입 경로를 안내해드려요.</span>
      </div>
      <div>
        <table class="table" v-if="state.result.length">
          <tbody>
          <tr v-for="(r, idx) in state.result" :key="idx">
            <td class="font-sm">
              <span>{{ r.memberId }}</span>
            </td>
            <td class="font-sm">
              <span>{{ r.joinType }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="col left">
          <button class="btn btn-point btn-block font-sm" @click="$store.commit('closeModal', {all: true})">로그인하기</button>
        </div>
        <div class="col right">
          <button class="btn btn-secondary btn-block font-sm" @click="findPassword()">비밀번호 찾기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalFindResult";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < 2; i += 1) {
        state.result.push({
          memberId: "Wait a moment",
          joinType: "Wait a moment"
        });
      }

      state.loaded = false;
      http.get("/api/member/login/find/id", modalParams.args).then(({data}) => {
        state.loaded = true;
        state.result = data.body;
      }).catch(httpError(() => {
        store.commit("closeModal", {name: component.name});
      }));

      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      loaded: false,
      result: []
    });

    const modalParams = store.getters.modalParams(component);

    const findPassword = () => {
      store.commit("openModal", {name: "FindPw"});
    };

    return {component, state, findPassword};
  }
});
</script>

<style lang="scss" scoped>
.find-result {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    table td {
      border: 0;
    }
  }

  .actions {
    .row {
      > div {
        button {
          padding: $px15;
          height: $px56;
          border-radius: 0;
        }

        &.left {
          padding-right: 0;
        }

        &.right {
          padding-left: 0;
        }
      }
    }
  }

  &.skeleton {
    .title > span {
      @include skeleton
    }

    .desc > span {
      @include skeleton
    }

    table td > span {
      @include skeleton
    }

    .actions .btn {
      @include skeleton
    }
  }
}
</style>